<template>
    <div class="answerList">
        <van-loading size="18" color="#1989fa" v-if="showLoading" style="text-align: center;margin-top:50px;" />
        <template v-else>
            <!-- 考题 -->
            <h5>{{ data.examName }}</h5>
            <div class="main">
                <div class="questions" v-for="item in questionsList" :key="item.ID">
                    <div class="title">
                        <em v-if="item.QuestionType === 'SingleChoice'">单选</em>
                        <em v-else-if="item.QuestionType === 'MultiChoice'">多选</em>
                        <em v-else-if="item.QuestionType === 'Judge'">判断</em>
                        <em v-else-if="item.QuestionType === 'FillIn'">填空</em>
                        <em v-else-if="item.QuestionType === 'QuestionAndAnswer'">问答</em>
                        <span v-if="item.OrderIndex">{{ item.OrderIndex }}、</span>
                        <span v-html="item.QuestionContent" style="word-break:break-all;"></span>
                        <i v-if="item.MaxScore"
                            >(<i>{{ item.Score && item.Score.toFixed(1) }}分</i>/{{ item.MaxScore }}分)</i
                        >
                    </div>
                    <div class="answer">
                        <ul>
                            <!-- 单选-->
                            <template v-if="item.QuestionType == 'SingleChoice'">
                                <li v-for="q in item.ChoiceItems" :key="q.ID">
                                    <!-- 'yes':q.IsAnswer&&data.isShowCorrectAnswers,'on':!q.IsAnswer&&q.IsSelected&&data.isShowCorrectAnswers,'sel':q.IsSelected&&!data.isShowCorrectAnswers -->
                                    <em :class="{ yes: q.IsSelected }">{{ q.ItemCode }}</em>
                                    <span v-html="q.ItemContent"></span>
                                </li>
                            </template>
                            <!-- 多选 -->
                            <template v-if="item.QuestionType == 'MultiChoice'">
                                <li v-for="q in item.ChoiceItems" :key="q.ID">
                                    <!-- 'yes':q.IsAnswer&&q.IsSelected&&data.isShowCorrectAnswers,'on':(!q.IsAnswer&&q.IsSelected||q.IsAnswer&&!q.IsSelected)&&data.isShowCorrectAnswers,'sel':q.IsSelected&&!data.isShowCorrectAnswers -->
                                    <em :class="{ yes: q.IsSelected }">{{ q.ItemCode }}</em>
                                    <span v-html="q.ItemContent"></span>
                                </li>
                            </template>
                            <!-- 判断 -->
                            <template v-if="item.QuestionType == 'Judge'">
                                <li v-for="q in item.JudgeItems" :key="q.ID">
                                    <!-- 'yes':q.IsAnswer&&data.isShowCorrectAnswers,'on':!q.IsAnswer&&q.IsSelected&&data.isShowCorrectAnswers,'sel':q.IsSelected&&!data.isShowCorrectAnswers -->
                                    <em :class="{ yes: q.IsSelected }">{{ q.ItemCode }}</em>
                                    <span v-html="q.ItemContent"></span>
                                </li>
                            </template>
                            <!-- 填空 -->
                            <template v-else-if="item.QuestionType == 'FillIn'">
                                <li v-for="q in item.FillInItems" :key="q.ID">
                                    <em>{{ q.ItemCode }}</em>
                                    <van-field placeholder="" readonly />
                                </li>
                            </template>
                            <!-- 问答题 -->
                            <template v-else-if="item.QuestionType == 'QuestionAndAnswer'">
                                <li>
                                    <van-field rows="3" autosize type="textarea" placeholder="" readonly />
                                </li>
                            </template>
                        </ul>
                    </div>
                    <div class="analyse">
                        <ul>
                            <template v-if="item.QuestionType == 'SingleChoice'">
                                <li>
                                    <p>
                                        <em>提交答案：</em><span>{{ selectQuestion(item, 0) }}</span>
                                    </p>
                                    <p>
                                        <em>正确答案：</em><span>{{ data.isShowCorrectAnswers ? selectQuestion(item, 1) : '不允许查看正确答案' }}</span>
                                    </p>
                                    <p><em>试题解析：</em><span v-html="data.isShowQuestionsResolution ? item.ExplainText : '不允许查看试题解析'"></span></p>
                                </li>
                            </template>
                            <template v-if="item.QuestionType == 'MultiChoice'">
                                <li>
                                    <p>
                                        <em>提交答案：</em><span>{{ selectQuestion(item, 0) }}</span>
                                    </p>
                                    <p>
                                        <em>正确答案：</em><span>{{ data.isShowCorrectAnswers ? selectQuestion(item, 1) : '不允许查看正确答案' }}</span>
                                    </p>
                                    <p><em>试题解析：</em><span v-html="data.isShowQuestionsResolution ? item.ExplainText : '不允许查看试题解析'"></span></p>
                                </li>
                            </template>
                            <template v-if="item.QuestionType == 'Judge'">
                                <li>
                                    <p>
                                        <em>提交答案：</em><span>{{ JudgeQuestion(item, 0) }}</span>
                                    </p>
                                    <p>
                                        <em>正确答案：</em><span>{{ data.isShowCorrectAnswers ? JudgeQuestion(item, 1) : '不允许查看正确答案' }}</span>
                                    </p>
                                    <p><em>试题解析：</em><span v-html="data.isShowQuestionsResolution ? item.ExplainText : '不允许查看试题解析'"></span></p>
                                </li>
                            </template>
                            <template v-if="item.QuestionType == 'FillIn'">
                                <li>
                                    <p>
                                        <em>提交答案：</em><span>{{ FillInQuestion(item, 0) }}</span>
                                    </p>
                                    <p><em>正确答案：</em><span v-html="data.isShowCorrectAnswers ? FillInQuestion(item, 1) : '不允许查看正确答案'"></span></p>
                                    <p><em>试题解析：</em><span v-html="data.isShowQuestionsResolution ? item.ExplainText : '不允许查看试题解析'"></span></p>
                                </li>
                            </template>
                            <template v-if="item.QuestionType == 'QuestionAndAnswer'">
                                <li>
                                    <p>
                                        <em>提交答案：</em><span>{{ QuestionAndAnswer(item, 0) }}</span>
                                    </p>
                                    <p><em>正确答案：</em><span v-html="data.isShowCorrectAnswers ? QuestionAndAnswer(item, 1) : '不允许查看正确答案'"></span></p>
                                    <p><em>试题解析：</em><span v-html="data.isShowQuestionsResolution ? item.ExplainText : '不允许查看试题解析'"></span></p>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { CountDown, Field, Toast, Loading } from 'vant'
import { examAnswerList } from '@/api/exam.api.js'
import dayjs from 'dayjs'
export default {
    name: 'answerList',
    components: {
        'van-count-down': CountDown,
        'van-field': Field,
        'van-loading': Loading
    },
    data() {
        return {
            arrangeId: null,
            userExamMapId: null,
            userExamId: null,
            questionsList: [],
            data: {},
            showLoading: false
        }
    },

    computed: {},
    watch: {},
    created() {
        this.userExamId = this.$route.query.userExamId
        this.arrangeId = this.$route.query.arrangeId
        this.userExamMapId = this.$route.query.userExamMapId
        this.init()
    },
    mounted() {},
    methods: {
        init() {
            this.getQuestions()
        },
        getQuestions() {
            this.showLoading = true
            examAnswerList(this.userExamId, this.arrangeId)
                .then(res => {
                    this.data = res
                    this.showLoading = false
                    let list = JSON.stringify(res.combinedQuestions)
                    this.questionsList = JSON.parse(list)
                })
                .catch(err => {
                    console.log('err---', err)
                })
        },
        selectQuestion(item, type) {
            if (!type) {
                // 0.用户选择 1.正确答案
                let selectedItem =
                    item.ChoiceItems &&
                    item.ChoiceItems.map(e => {
                        return e.IsSelected ? e.ItemCode : ''
                    })
                return selectedItem.join(' ')
            } else {
                let answerItem =
                    item.ChoiceItems &&
                    item.ChoiceItems.map(e => {
                        return e.IsAnswer ? e.ItemCode : ''
                    })
                return answerItem.join(' ')
            }
        },
        JudgeQuestion(item, type) {
            if (!type) {
                let selectedItem =
                    item.JudgeItems &&
                    item.JudgeItems.map(e => {
                        return e.IsSelected ? e.ItemCode : ''
                    })
                return selectedItem.join(' ')
            } else {
                let answerItem =
                    item.JudgeItems &&
                    item.JudgeItems.map(e => {
                        return e.IsAnswer ? e.ItemCode : ''
                    })
                return answerItem.join(' ')
            }
        },
        FillInQuestion(item, type) {
            if (!type) {
                let selectedItem =
                    item.FillInItems &&
                    item.FillInItems.map(e => {
                        return e.SubmitAnswer ? `${e.ItemCode}. ${e.SubmitAnswer}` : `${e.ItemCode}. ___`
                    })
                return selectedItem.join(', ')
            } else {
                let answerItem =
                    item.FillInItems &&
                    item.FillInItems.map(e => {
                        return `${e.ItemCode}. ${e.ItemAnswer}`
                    })
                return answerItem.join(', ')
            }
        },
        QuestionAndAnswer(item, type) {
            if (!type) {
                return item.SubmitContent ? item.SubmitContent : '___'
            } else {
                return item.AnswerContent
            }
        }
    }
}
</script>

<style lang="less" scoped>
.answerList {
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: 100%;
    h5 {
        font-size: 32px;
        color: @color3;
        text-align: center;
        padding: 40px 0;
    }
    .main {
        flex: 1;
        width: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
    .van-divider {
        color: @blueTheme;
        border-color: #d8d8d8;
        margin: 46px 30px 0px 30px;
        .van-count-down {
            color: @blueTheme;
            margin-left: 20px;
            width: 120px;
        }
    }
    .questions {
        margin: 0 30px;
        font-size: 32px;
        color: @color3;
        .title {
            padding-top: 40px;
            line-height: 53px;
            letter-spacing: 2px;
            text-align: justify;
            /deep/span div {
                display: inline;
            }
            em {
                padding: 0 15px;
                height: 40px;
                background: #e6f4ff;
                border-radius: 2px 100px 100px 2px;
                font-size: 22px;
                color: @blueTheme;
                display: inline-block;
                line-height: 39px;
                text-align: center;
                margin-right: 30px;
            }
            i {
                height: 40px;
                display: inline-block;
                font-size: 24px;
                color: #999999;
                line-height: 40px;
                text-align: center;
                margin-left: 10px;
                i {
                    color: @blueTheme;
                }
            }
        }
        .answer {
            padding-top: 30px;
            font-size: 28px;
            color: @color3;
            li {
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                span {
                    line-height: 40px;
                    word-break: break-all;
                }
                em {
                    min-width: 54px;
                    height: 54px;
                    line-height: 54px;
                    text-align: center;
                    font-size: 28px;
                    color: @color3;
                    display: inline-block;
                    background: #ffffff;
                    border-radius: 50%;
                    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.17);
                    margin-right: 20px;
                    // 正确答案的样式
                    &.yes {
                        background: #41c160;
                        color: #fff;
                    }
                    // 选中错误的样式
                    &.on {
                        background: #f3540a;
                        color: #fff;
                    }
                    &.sel {
                        background: @blueTheme;
                        color: #fff;
                    }
                }
                .van-cell {
                    border: 1px solid #979797;
                    border-radius: 4px;
                    padding: 10px;
                }
            }
        }
        .analyse {
            // margin: 0 30px;
            li {
                background: #f7f8fa;
                padding: 20px 30px;
                border-radius: 10px;
                p {
                    margin: 20px 0;
                    line-height: 40px;
                    em {
                        font-size: 28px;
                        color: #999;
                    }
                    span {
                        font-size: 28px;
                        color: @color3;
                        word-break: break-all;
                    }
                }
            }
        }
        .van-field {
            border: 1px solid #979797;
            border-radius: 4px;
        }
    }
}
</style>
