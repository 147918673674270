var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "answerList" },
    [
      _vm.showLoading
        ? _c("van-loading", {
            staticStyle: { "text-align": "center", "margin-top": "50px" },
            attrs: { size: "18", color: "#1989fa" }
          })
        : [
            _c("h5", [_vm._v(_vm._s(_vm.data.examName))]),
            _c(
              "div",
              { staticClass: "main" },
              _vm._l(_vm.questionsList, function(item) {
                return _c("div", { key: item.ID, staticClass: "questions" }, [
                  _c("div", { staticClass: "title" }, [
                    item.QuestionType === "SingleChoice"
                      ? _c("em", [_vm._v("单选")])
                      : item.QuestionType === "MultiChoice"
                      ? _c("em", [_vm._v("多选")])
                      : item.QuestionType === "Judge"
                      ? _c("em", [_vm._v("判断")])
                      : item.QuestionType === "FillIn"
                      ? _c("em", [_vm._v("填空")])
                      : item.QuestionType === "QuestionAndAnswer"
                      ? _c("em", [_vm._v("问答")])
                      : _vm._e(),
                    item.OrderIndex
                      ? _c("span", [_vm._v(_vm._s(item.OrderIndex) + "、")])
                      : _vm._e(),
                    _c("span", {
                      staticStyle: { "word-break": "break-all" },
                      domProps: { innerHTML: _vm._s(item.QuestionContent) }
                    }),
                    item.MaxScore
                      ? _c("i", [
                          _vm._v("("),
                          _c("i", [
                            _vm._v(
                              _vm._s(item.Score && item.Score.toFixed(1)) + "分"
                            )
                          ]),
                          _vm._v("/" + _vm._s(item.MaxScore) + "分)")
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "answer" }, [
                    _c(
                      "ul",
                      [
                        item.QuestionType == "SingleChoice"
                          ? _vm._l(item.ChoiceItems, function(q) {
                              return _c("li", { key: q.ID }, [
                                _c("em", { class: { yes: q.IsSelected } }, [
                                  _vm._v(_vm._s(q.ItemCode))
                                ]),
                                _c("span", {
                                  domProps: { innerHTML: _vm._s(q.ItemContent) }
                                })
                              ])
                            })
                          : _vm._e(),
                        item.QuestionType == "MultiChoice"
                          ? _vm._l(item.ChoiceItems, function(q) {
                              return _c("li", { key: q.ID }, [
                                _c("em", { class: { yes: q.IsSelected } }, [
                                  _vm._v(_vm._s(q.ItemCode))
                                ]),
                                _c("span", {
                                  domProps: { innerHTML: _vm._s(q.ItemContent) }
                                })
                              ])
                            })
                          : _vm._e(),
                        item.QuestionType == "Judge"
                          ? _vm._l(item.JudgeItems, function(q) {
                              return _c("li", { key: q.ID }, [
                                _c("em", { class: { yes: q.IsSelected } }, [
                                  _vm._v(_vm._s(q.ItemCode))
                                ]),
                                _c("span", {
                                  domProps: { innerHTML: _vm._s(q.ItemContent) }
                                })
                              ])
                            })
                          : item.QuestionType == "FillIn"
                          ? _vm._l(item.FillInItems, function(q) {
                              return _c(
                                "li",
                                { key: q.ID },
                                [
                                  _c("em", [_vm._v(_vm._s(q.ItemCode))]),
                                  _c("van-field", {
                                    attrs: { placeholder: "", readonly: "" }
                                  })
                                ],
                                1
                              )
                            })
                          : item.QuestionType == "QuestionAndAnswer"
                          ? [
                              _c(
                                "li",
                                [
                                  _c("van-field", {
                                    attrs: {
                                      rows: "3",
                                      autosize: "",
                                      type: "textarea",
                                      placeholder: "",
                                      readonly: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]),
                  _c("div", { staticClass: "analyse" }, [
                    _c(
                      "ul",
                      [
                        item.QuestionType == "SingleChoice"
                          ? [
                              _c("li", [
                                _c("p", [
                                  _c("em", [_vm._v("提交答案：")]),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.selectQuestion(item, 0)))
                                  ])
                                ]),
                                _c("p", [
                                  _c("em", [_vm._v("正确答案：")]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.data.isShowCorrectAnswers
                                          ? _vm.selectQuestion(item, 1)
                                          : "不允许查看正确答案"
                                      )
                                    )
                                  ])
                                ]),
                                _c("p", [
                                  _c("em", [_vm._v("试题解析：")]),
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.data.isShowQuestionsResolution
                                          ? item.ExplainText
                                          : "不允许查看试题解析"
                                      )
                                    }
                                  })
                                ])
                              ])
                            ]
                          : _vm._e(),
                        item.QuestionType == "MultiChoice"
                          ? [
                              _c("li", [
                                _c("p", [
                                  _c("em", [_vm._v("提交答案：")]),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.selectQuestion(item, 0)))
                                  ])
                                ]),
                                _c("p", [
                                  _c("em", [_vm._v("正确答案：")]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.data.isShowCorrectAnswers
                                          ? _vm.selectQuestion(item, 1)
                                          : "不允许查看正确答案"
                                      )
                                    )
                                  ])
                                ]),
                                _c("p", [
                                  _c("em", [_vm._v("试题解析：")]),
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.data.isShowQuestionsResolution
                                          ? item.ExplainText
                                          : "不允许查看试题解析"
                                      )
                                    }
                                  })
                                ])
                              ])
                            ]
                          : _vm._e(),
                        item.QuestionType == "Judge"
                          ? [
                              _c("li", [
                                _c("p", [
                                  _c("em", [_vm._v("提交答案：")]),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.JudgeQuestion(item, 0)))
                                  ])
                                ]),
                                _c("p", [
                                  _c("em", [_vm._v("正确答案：")]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.data.isShowCorrectAnswers
                                          ? _vm.JudgeQuestion(item, 1)
                                          : "不允许查看正确答案"
                                      )
                                    )
                                  ])
                                ]),
                                _c("p", [
                                  _c("em", [_vm._v("试题解析：")]),
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.data.isShowQuestionsResolution
                                          ? item.ExplainText
                                          : "不允许查看试题解析"
                                      )
                                    }
                                  })
                                ])
                              ])
                            ]
                          : _vm._e(),
                        item.QuestionType == "FillIn"
                          ? [
                              _c("li", [
                                _c("p", [
                                  _c("em", [_vm._v("提交答案：")]),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.FillInQuestion(item, 0)))
                                  ])
                                ]),
                                _c("p", [
                                  _c("em", [_vm._v("正确答案：")]),
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.data.isShowCorrectAnswers
                                          ? _vm.FillInQuestion(item, 1)
                                          : "不允许查看正确答案"
                                      )
                                    }
                                  })
                                ]),
                                _c("p", [
                                  _c("em", [_vm._v("试题解析：")]),
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.data.isShowQuestionsResolution
                                          ? item.ExplainText
                                          : "不允许查看试题解析"
                                      )
                                    }
                                  })
                                ])
                              ])
                            ]
                          : _vm._e(),
                        item.QuestionType == "QuestionAndAnswer"
                          ? [
                              _c("li", [
                                _c("p", [
                                  _c("em", [_vm._v("提交答案：")]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.QuestionAndAnswer(item, 0))
                                    )
                                  ])
                                ]),
                                _c("p", [
                                  _c("em", [_vm._v("正确答案：")]),
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.data.isShowCorrectAnswers
                                          ? _vm.QuestionAndAnswer(item, 1)
                                          : "不允许查看正确答案"
                                      )
                                    }
                                  })
                                ]),
                                _c("p", [
                                  _c("em", [_vm._v("试题解析：")]),
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.data.isShowQuestionsResolution
                                          ? item.ExplainText
                                          : "不允许查看试题解析"
                                      )
                                    }
                                  })
                                ])
                              ])
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                ])
              }),
              0
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }